import { IonIcon, useIonAlert } from "@ionic/react";
import styles from "./DeviceTransitPolicyTile.module.css";
import { addOutline } from "ionicons/icons";

const DeviceTransitPolicyAddButton: React.FC = () => {
    const [presentAlert] = useIonAlert();

    function addTransitPolicy() {
        presentAlert({
            header: 'Coming Soon',
            message: "We're actively working on this feature. In the meantime, you can ask the OnlyCat team to help configure door policies.",
            buttons: ['OK']
        });
    }

    return (
        <button className={`${styles.tile} ${styles.add}`} onClick={addTransitPolicy}>
            <IonIcon icon={addOutline} />
            <label>Add</label>
        </button>
    );
};

export default DeviceTransitPolicyAddButton;
