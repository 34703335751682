import OCGatewayClient from "../client/OCGatewayClient";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DeviceTransitPolicy } from "../models/DeviceTransitPolicy";
import { Device } from "../models/Device";

export function useDeviceTransitPolicyQuery(deviceTransitPolicyId: number) {
    const query = useQuery({
        queryKey: ['getDeviceTransitPolicy', deviceTransitPolicyId],
        queryFn: async () => {
            return OCGatewayClient.request('getDeviceTransitPolicy', {
                deviceTransitPolicyId
            }) as Promise<DeviceTransitPolicy>;
        },
        staleTime: 1000 * 60 * 5,
        gcTime: 1000 * 60 * 60 * 24,
        networkMode: 'always',
        retry: false
    });

    return query;
}

export function useDeviceTransitPolicyActivateMutation(deviceId: string) {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (deviceTransitPolicyId: number) => {
            await OCGatewayClient.request('activateDeviceTransitPolicy', {
                deviceId,
                deviceTransitPolicyId
            });
            return deviceTransitPolicyId;
        },
        onMutate: async (deviceTransitPolicyId: number) => {
            await queryClient.cancelQueries({ queryKey: ['getDevice', deviceId] });

            const previousDevice = queryClient.getQueryData(['getDevice', deviceId]) as Device;

            queryClient.setQueryData(['getDevice', deviceId], (queryData: any) => {
                return Object.assign({}, queryData, { deviceTransitPolicyId });
            });
            
            return { previousDevice };
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(['getDevice', deviceId], (queryData: any) => {
                return Object.assign({}, queryData, { deviceTransitPolicyId: context?.previousDevice?.deviceTransitPolicyId });
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['getDevice', deviceId] });
        }
    });

    return mutation;
};