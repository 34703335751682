import { IonContent, IonHeader, IonChip, IonIcon, IonList, IonPage, IonTitle, IonToolbar, IonPopover, IonButton, IonText, useIonModal } from '@ionic/react';
import { IonButtons, IonBackButton, IonItem, IonLabel, IonItemDivider, IonProgressBar } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import DeviceEventItem from '../components/DeviceEventItem';
import { useDeviceEventsQuery } from '../query/deviceEventsQuery';
import { useMemo } from 'react';
import { useDeviceQuery } from '../query/deviceQuery';
import { chevronExpandOutline, ellipsisVertical, unlinkOutline, wifi } from "ionicons/icons";
import DeviceTransitPolicyTile from '../components/DeviceTransitPolicyTile';
import DeviceTransitPolicyAddButton from '../components/DeviceTransitPolicyAddButton';
import { useDeviceTransitPoliciesQuery } from '../query/deviceTransitPoliciesQuery';
import styles from './Device.module.css';
import OCGatewayClient from '../client/OCGatewayClient';
import useUX from '../utils/ux';
import DeviceTimeZoneModal from '../modals/DeviceTimeZoneModal';

interface DevicePageProps
    extends RouteComponentProps<{
        deviceId: string;
    }> { }

const Device: React.FC<DevicePageProps> = ({ match }) => {
    const { deviceId } = match.params;
    const deviceQuery = useDeviceQuery(deviceId);
    const deviceEventsQuery = useDeviceEventsQuery(deviceId);
    const deviceTransitPoliciesQuery = useDeviceTransitPoliciesQuery(deviceId);
    const { presentResult } = useUX();

    const events = useMemo(() => {
        return deviceEventsQuery.data?.pages.reduce((acc, page) => acc.concat(page), []) ?? [];
    }, [deviceEventsQuery.data]);

    const unlock = async () => {
        presentResult(() => OCGatewayClient.request('runDeviceCommand', { deviceId, command: 'unlock' }), "Remote Unlock Requested");
    };

    const reboot = async () => {
        presentResult(() => OCGatewayClient.request('runDeviceCommand', { deviceId, command: 'reboot' }), "Reboot Requested");
    };

    const [presentTimeZoneModal, dismissTimeZoneModal] = useIonModal(DeviceTimeZoneModal, { 
        dismiss: (data: string, role: string) => dismissTimeZoneModal(data, role),
        deviceId
    });

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/devices" />
                    </IonButtons>
                    <IonTitle>Device</IonTitle>
                    <IonButtons slot="primary">
                        <IonButton id="device-more" fill="clear">
                            <IonIcon slot="icon-only" icon={ellipsisVertical} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                {(deviceQuery.isLoading || (deviceEventsQuery.isLoading && !deviceEventsQuery.isFetchingNextPage)) && <IonProgressBar type="indeterminate" />}
            </IonHeader>
            <IonContent fullscreen>
                <IonList inset={true}>
                    <IonItemDivider>Device Information</IonItemDivider>
                    <IonItem>
                        <IonLabel>Device Id</IonLabel>
                        <IonText slot="end" color="dark">{deviceId}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Device Name</IonLabel>
                        <IonText slot="end" color="dark">{deviceQuery.data?.description ?? '-'}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Device Status</IonLabel>
                        {deviceQuery.data?.connectivity
                            ? <IonChip color={deviceQuery.data.connectivity.connected ? 'success' : 'danger'}>
                                <IonIcon icon={deviceQuery.data.connectivity.connected ? wifi : unlinkOutline} />
                                <IonLabel>{deviceQuery.data.connectivity.connected ? 'Online' : 'Offline'}</IonLabel>
                            </IonChip>
                            : '-'}
                    </IonItem>
                    <IonItem onClick={() => presentTimeZoneModal()} button detail detailIcon={chevronExpandOutline}>
                        <IonLabel>Time Zone</IonLabel>
                        <IonText slot="end" color="medium">{deviceQuery.data?.timeZone || 'Not Set'}</IonText>
                    </IonItem>
                </IonList>
                <IonList className={styles.transitPolicies}>
                    <IonItemDivider>Door Policy</IonItemDivider>
                    {deviceTransitPoliciesQuery.data && <IonItem lines="none">
                        <div>
                            {deviceTransitPoliciesQuery.data.map((policy) => <DeviceTransitPolicyTile
                                key={policy.deviceTransitPolicyId}
                                deviceTransitPolicyId={policy.deviceTransitPolicyId!}
                                active={policy.deviceTransitPolicyId === deviceQuery.data?.deviceTransitPolicyId}
                            />)}
                            <DeviceTransitPolicyAddButton />
                        </div>
                    </IonItem>}
                </IonList>
                <IonList inset={true}>
                    <IonItemDivider>Recent Events</IonItemDivider>
                    {events?.map((event: any) => <DeviceEventItem key={event.eventId} deviceId={event.deviceId} eventId={event.eventId} />)}
                </IonList>
            </IonContent>
            <IonPopover trigger="device-more" dismissOnSelect>
                <IonContent>
                    <IonList>
                        <IonItem button={true} detail={false} onClick={unlock}>Remote Unlock</IonItem>
                        <IonItem button={true} detail={false} onClick={reboot}>Reboot</IonItem>
                    </IonList>
                </IonContent>
            </IonPopover>
        </IonPage >
    );
};

export default Device;
