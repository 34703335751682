import {io, Socket} from "socket.io-client";
import {BehaviorSubject} from 'rxjs';
import OCPlatform from "./OCPlatform";

enum ConnectionState {
    Disconnected = "DISCONNECTED",
    Connecting = "CONNECTING",
    Connected = "CONNECTED",
    Reconnecting = "RECONNECTING",
}

class OCGatewayClient {
    requestId: number = 0;
    gatewayURL: string;
    apiURL: string;
    socket: Socket;
    connectionState$: BehaviorSubject<ConnectionState>;
    user$: BehaviorSubject<any>;

    constructor() {
        this.gatewayURL = `https://gateway.onlycat.com`;
        this.apiURL = this.gatewayURL;

        console.log(`(${this.constructor.name}) Connecting to ${this.gatewayURL}`);

        this.connectionState$ = new BehaviorSubject<ConnectionState>(ConnectionState.Connecting);
        this.user$ = new BehaviorSubject<any>(undefined);

        this.socket = io(this.gatewayURL, {
            transports: ['websocket'],
            query: {
                platform: OCPlatform.getPlatforms(),
                device: "ionic-app"
            },
            auth: (async (cb: any) => {
                cb({
                    token: await OCPlatform.getDeviceToken()
                });
            })
        });

        this.socket.on('connect', () => {
            console.info(`(${this.constructor.name}) Connected.`);
            this.connectionState$.next(ConnectionState.Connected);
        });

        this.socket.on('connect_error', (error: any) => {
            console.warn(`(${this.constructor.name}) Connect Error`, error);
        });

        this.socket.on('disconnect', () => {
            console.warn(`(${this.constructor.name}) Disconnected.`);
            this.connectionState$.next(ConnectionState.Disconnected);
        });

        this.socket.io.on('reconnect_attempt', () => {
            console.info(`(${this.constructor.name}) Reconnect attempt`);
            this.connectionState$.next(ConnectionState.Reconnecting)
        });

        this.socket.io.on('reconnect', () => {
            console.info(`(${this.constructor.name}) Reconnect success`);
        });

        this.socket.on("userUpdate", (user: any) => {
            console.info("(IWBrokerClient) userUpdate", user);
            this.user$.next(user);
        });
    }

    async socketRequest(socket: any, event: string, ...args: any[]) {
        const requestId = ++this.requestId;

        return new Promise((resolve, reject) => {
            console.log(`[${requestId}] -> `, event, args);

            const disconnectHandler = () => {
                console.log(`[${requestId}] <-x- DISCONNECTED`);

                reject({
                    code: 1006,
                    message: 'Disconnected'
                });
            };

            const timeoutHandler = () => {
                console.warn(`[${requestId}] <-?- Request Timeout?`);
            };

            this.socket.once('disconnect', disconnectHandler);
            const timeout = setTimeout(timeoutHandler, 30000);

            this.socket.emit(event, ...args, (response: any) => {
                this.socket.off('disconnect', disconnectHandler);
                clearInterval(timeout);

                console.log(`[${requestId}] <- `, event, response);

                if (response?.code && response.code !== 200) {
                    console.error(event, response);
                    reject(response);
                }

                resolve(response);
            });
        });
    }

    async volatileRequest(event: string, ...args: any[]) {
        return this.socketRequest(this.socket.volatile, event, ...args);
    }

    async request(event: string, ...args: any[]) {
        return this.socketRequest(this.socket, event, ...args);
    }

    async httpRequest(method: string, path: string, data: object): Promise<Response> {
        return await fetch(this.apiURL + path, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
}

export default new OCGatewayClient();