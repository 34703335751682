import OCGatewayClient from "../client/OCGatewayClient";
import { useQuery } from "@tanstack/react-query";
import { DeviceTransitPolicy } from "../models/DeviceTransitPolicy";

export function useDeviceTransitPoliciesQuery(deviceId: string) {
    const query = useQuery({
        queryKey: ['getDeviceTransitPolicies', deviceId],
        queryFn: async () => {
            return OCGatewayClient.request('getDeviceTransitPolicies', {
                deviceId
            }) as Promise<DeviceTransitPolicy[]>;
        },
        staleTime: 1000 * 60 * 5,
        gcTime: 1000 * 60 * 60 * 24,
        networkMode: 'always',
        retry: false
    });

    return query;
}