import { IonButton, IonContent, IonHeader, IonIcon, IonList, IonNote, IonPage, IonText, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonPopover } from '@ionic/react';
import { IonButtons, IonBackButton, IonItem, IonLabel, IonItemDivider, IonProgressBar } from '@ionic/react';
import { memo } from 'react';
import { RouteComponentProps } from 'react-router';
import { VideoPlayer } from '../components/VideoPlayer'
import { formatRelative, formatDuration } from 'date-fns';
import { Share } from '@capacitor/share';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { copyOutline, downloadOutline, lockOpenOutline, openOutline, shareOutline, shareSocial } from 'ionicons/icons';
import OCGatewayClient from '../client/OCGatewayClient';
import RfidChip from '../components/RfidChip';
import styles from './Event.module.css';
import { useDeviceEventQuery } from '../query/deviceEventQuery';
import { useDeviceQuery } from '../query/deviceQuery';
import useUX from '../utils/ux';
import { useHistory } from 'react-router-dom';

interface DeviceEventPageProps
    extends RouteComponentProps<{
        deviceId: string;
        eventId: string;
    }> { }

const Device: React.FC<DeviceEventPageProps> = ({ match }) => {
    const { deviceId } = match.params;
    const eventId = parseInt(match.params.eventId);
    const eventQuery = useDeviceEventQuery(deviceId, eventId);
    const deviceQuery = useDeviceQuery(deviceId);
    const { presentResult } = useUX();
    const [presentLoading, dismissLoading] = useIonLoading();
    const [presentAlert] = useIonAlert();
    const history = useHistory();

    const videoPlayerOptions = {
        autoplay: true,
        controls: true,
        controlBar: {
            volumePanel: false,
            pictureInPictureToggle: false
        },
        responsive: true,
        fluid: true,
        aspectRatio: '4:3',
        liveui: true,
        liveTracker: {
            trackingThreshold: 0,
            liveTolerance: 5
        },
        muted: true,
        playsinline: true,
        poster: `${OCGatewayClient.apiURL}/events/${deviceId}/${eventId}/${eventQuery?.data?.posterFrameIndex || 0}`,
        sources: [{
            src: `${OCGatewayClient.apiURL}/videos/${deviceId}/${eventId}`,
            type: 'application/x-mpegURL'
        }]
    };

    const onReady = (player: any) => {
        player.reloadSourceOnError({
            errorInterval: 5,
        });
    };

    const copyDeviceId = () => {
        presentResult(async () => {
            await navigator.clipboard.writeText(deviceId);
        }, "Device Id Copied");
    }

    const unlock = async () => {
        presentResult(() => OCGatewayClient.request('runDeviceCommand', { deviceId, command: 'unlock' }), "Remote Unlock Requested");
    };

    const DevicePopover = () => <IonContent>
        <IonList>
            <IonItem button detail={false} onClick={copyDeviceId}>
                <IonIcon slot="start" src="/assets/device/onlycat-device-icon.svg" />
                <div>
                    <IonLabel>Device Id</IonLabel>
                    <IonNote class="ion-text-nowrap">{deviceId}</IonNote>
                </div>
                <IonIcon slot="end" icon={copyOutline} />
            </IonItem>
            <IonItem button detail={false} onClick={unlock}>
                <IonIcon slot="start" icon={lockOpenOutline} />
                <IonLabel>Remote Unlock</IonLabel>
            </IonItem>
            <IonItem button onClick={() => history.push(`/devices/${deviceId}`)}>
                <IonIcon slot="start" icon={openOutline} />
                <IonLabel>Show Device</IonLabel>
            </IonItem>
        </IonList>
    </IonContent>

    const [presentDevicePopover, dismissDevicePopover] = useIonPopover(DevicePopover, {
        onDismiss: (data: any, role: string) => dismissDevicePopover(data, role),
    });

    const share = () => {
        Share.share({
            title: 'OnlyCat Event',
            text: 'Check out this event from my OnlyCat Cat Flap!',
            url: `https://onlycat.app/events/${deviceId}/${eventId}?t=${eventQuery.data?.accessToken}`,
            dialogTitle: 'Share Event'
        }).catch(error => console.log(error));
    };

    const downloadVideo = async () => {
        await presentLoading({
            message: 'Downloading Your Video...',
            backdropDismiss: false,
            spinner: 'dots',
        });

        try {
            const result = await Filesystem.downloadFile({
                url: `${OCGatewayClient.apiURL}/sharing/video/${deviceId}/${eventId}?t=${eventQuery.data?.accessToken}`,
                path: `${deviceId}-${eventId}.mp4`,
                directory: Directory.Cache
            });

            if (result.blob) {
                const blobUrl = URL.createObjectURL(result.blob);
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = `${deviceId}-${eventId}.mp4`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(blobUrl);
            }
            else if (result.path) {
                Share.share({
                    title: 'OnlyCat Event Video',
                    text: 'Check out this video from my OnlyCat Cat Flap!',
                    files: ['file://' + result.path],
                    dialogTitle: 'Share Video'
                });
            }
        }
        catch (error: any) {
            presentAlert({
                header: 'Download Error',
                message: 'Your video could not be downloaded. Please try again later.',
                buttons: ['OK']
            });
        }
        finally {
            await dismissLoading();
        }
    };

    const SharingPopover = () => <IonContent>
        <IonList>
            <IonItem button detail={false} onClick={share}>
                <IonIcon slot="start" icon={shareOutline} />
                <IonLabel>Share Event Link</IonLabel>
            </IonItem>
            <IonItem button detail={false} onClick={downloadVideo}>
                <IonIcon slot="start" icon={downloadOutline} />
                <IonLabel>Download Video</IonLabel>
            </IonItem>
        </IonList>
    </IonContent>

    const [presentSharingPopover, dismissSharingPopover] = useIonPopover(SharingPopover, {
        onDismiss: (data: any, role: string) => dismissSharingPopover(data, role),
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/activity" />
                    </IonButtons>
                    <IonTitle>Device Event</IonTitle>
                    <IonButtons slot="primary">
                        <IonButton disabled={!eventQuery.isSuccess} onClick={(e: any) => presentSharingPopover({
                            event: e,
                            dismissOnSelect: true,
                            cssClass: styles.popover
                        })}>
                            <IonIcon slot="icon-only" ios={shareOutline} md={shareSocial} aria-label="Share Event"></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                {eventQuery.isLoading && <IonProgressBar type="indeterminate" />}
            </IonHeader>
            <IonContent fullscreen>
                <VideoPlayer options={videoPlayerOptions} onReady={onReady} />
                <IonList inset={true}>
                    <IonItemDivider>Event #{eventId}</IonItemDivider>
                    <IonItem>
                        <IonLabel>Device</IonLabel>
                        <IonText className={styles.tapable} onClick={(e: any) => presentDevicePopover({
                            event: e,
                            dismissOnSelect: true,
                            cssClass: styles.popover
                        })}>{deviceQuery.data?.description ?? deviceId}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Time</IonLabel>
                        {eventQuery.data?.timestamp ? formatRelative(eventQuery.data?.timestamp, new Date()) : '-'}
                    </IonItem>
                    <IonItem>
                        <IonLabel>Duration</IonLabel>
                        {eventQuery.data?.frameCount ? formatDuration({
                            seconds: eventQuery.data?.frameCount / 10
                        }) : '-'}
                    </IonItem>
                    {eventQuery.data?.rfidCodes && <IonItem>
                        <IonLabel>Scanned Ids</IonLabel>
                        <div className={styles.chips}>
                            {eventQuery.data?.rfidCodes.map(rfidCode => <RfidChip key={rfidCode} rfidCode={rfidCode} />)}
                        </div>
                    </IonItem>}
                </IonList>
            </IonContent>
        </IonPage >
    );
};

export default memo(Device);
