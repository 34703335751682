import OCGatewayClient from "../client/OCGatewayClient";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Device } from "../models/Device";

let subscribed = false;

export function useDeviceQuery(deviceId: string) {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ['getDevice', deviceId],
        enabled: !!deviceId,
        queryFn: async (): Promise<any> => {
            return OCGatewayClient.request('getDevice', {
                deviceId,
                subscribe: true
            });
        },
        staleTime: Infinity,
        gcTime: 1000 * 60 * 60 * 24,
        networkMode: 'always',
        retry: false
    });

    if (!subscribed) {
        OCGatewayClient.user$.subscribe(user => {
            console.log(`(devicesQuery) User changed, invalidating devices...`);
            queryClient.invalidateQueries({
                queryKey: ['getDevice']
            });
        });

        OCGatewayClient.socket.on('deviceUpdate', (data: any) => {
            queryClient.setQueryData(['getDevice', data.deviceId], (device: Device) => {
                if (!device) {
                    console.log('deviceUpdate (ignored)', data);
                    return;
                }

                if (data.type === 'update') {
                    console.log('deviceUpdate (relevant update)', data);
                    return Object.assign({}, device, data.body);
                }

                console.log('deviceUpdate (no match)', data);
            });
        });

        subscribed = true;
    }

    return query;
}
