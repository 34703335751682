import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonModal, IonSpinner, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { IonButtons } from '@ionic/react';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { alertCircleOutline, arrowBack, close, lockClosed, qrCode } from 'ionicons/icons';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import {
    BarcodeScanner,
    BarcodeFormat,
} from '@capacitor-mlkit/barcode-scanning';
import { CapacitorHttp } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';

import styles from './AddDeviceModal.module.css';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import OCGatewayClient from '../client/OCGatewayClient';

const PAGES = {
    SCAN_QR: 0,
    CONNECT: 1,
    SCAN_WIFI: 2,
    CREDENTIALS: 3,
    JOIN: 4,
    CLAIM: 5
};

interface AddDeviceModalProps {
    trigger: string
}

function rssiToSignalIcon(rssi: number): string {
    if (rssi >= -50) return '/assets/wifi/wifi-signal-strength-5.svg'; // Excellent signal
    if (rssi >= -60) return '/assets/wifi/wifi-signal-strength-4.svg'; // Very good signal
    if (rssi >= -70) return '/assets/wifi/wifi-signal-strength-3.svg'; // Good signal
    if (rssi >= -80) return '/assets/wifi/wifi-signal-strength-2.svg'; // Fair signal
    if (rssi >= -90) return '/assets/wifi/wifi-signal-strength-1.svg'; // Weak signal
    return '/assets/wifi/wifi-signal-strength-0.svg'; // No signal or very poor signal
}

let swiperInstance: SwiperClass;

const AddDeviceModal: React.FC<AddDeviceModalProps> = ({ trigger }) => {
    const queryClient = useQueryClient();
    const [isOpen, setIsOpen] = useState(false);
    const [slideIndex, setSlideIndex] = useState<number>(0);
    const [networkStatus, setNetworkStatus] = useState<ConnectionStatus | undefined>();
    const [ssid, setSsid] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const modal = useRef<HTMLIonModalElement>(null);

    const qrCodeScanQuery = useQuery({
        queryKey: ['setup/scanDeviceCode'],
        networkMode: 'always',
        retry: false,
        refetchOnWindowFocus: false,
        enabled: isOpen && swiperInstance && slideIndex === PAGES.SCAN_QR,
        staleTime: 0,
        queryFn: async ({ signal }): Promise<string> => {
            if (!await BarcodeScanner.isSupported()) {
                throw new Error('Barcode scanning is not supported on this device');
            }

            return new Promise(async resolve => {
                document.querySelector('body')?.classList.add('barcode-scanner-active');

                const listener = await BarcodeScanner.addListener(
                    'barcodeScanned',
                    async result => {
                        const value = result.barcode.rawValue;
                        const regex = /^(?:https:\/\/onlycat\.app\/)?(.{12})$/;
                        const match = value.match(regex);

                        if (match) {
                            await listener.remove();
                            document.querySelector('body')?.classList.remove('barcode-scanner-active');
                            await BarcodeScanner.stopScan();
                            Haptics.notification({ type: NotificationType.Success });
                            swiperInstance!.slideNext();
                            resolve(match[1]);
                        }
                    },
                );

                BarcodeScanner.startScan({
                    formats: [BarcodeFormat.QrCode]
                });

                signal.addEventListener('abort', async () => {
                    await listener.remove();
                    document.querySelector('body')?.classList.remove('barcode-scanner-active');
                    await BarcodeScanner.stopScan();
                }, { once: true });
            });
        },
    });

    const connectToDeviceQuery = useQuery({
        queryKey: ['setup/connectToDeviceWifi'],
        networkMode: 'always',
        retry: false,
        refetchOnWindowFocus: false,
        enabled: isOpen && slideIndex === PAGES.CONNECT,
        staleTime: 0,
        queryFn: async () => {
            const ssid = 'OnlyCat-' + qrCodeScanQuery.data!.substring(6, 12);

            try {
                await new Promise((resolve, reject) => {
                    (window as any).wifiManager.connect(ssid, '', () => {
                        resolve(ssid);
                    }, (e: any) => {
                        reject(e);
                    });
                });

                Haptics.notification({ type: NotificationType.Success });
                swiperInstance!.slideNext();

                return ssid;
            }
            catch (e: any) {
                const strError = JSON.stringify(e);
                Haptics.notification({ type: NotificationType.Error });
                console.error('connectDeviceToWifi error', e);

                if (e?.code === 13) {
                    console.log("Device is already connected to the Wi-Fi network");
                    swiperInstance!.slideNext();
                    return ssid;
                }
                else {
                    throw new Error(strError);
                }
            }
        },
    });

    let scanNetworksQuery = useQuery({
        queryKey: ['setup/scanWifiNetworks'],
        networkMode: 'always',
        retry: false,
        enabled: isOpen && slideIndex === PAGES.SCAN_WIFI && (networkStatus?.connectionType === 'wifi' || (networkStatus?.connectionType === 'none' && isPlatform('android'))),
        staleTime: 0,
        refetchInterval: 5000,
        queryFn: async ({ signal }) => {
            const res = await CapacitorHttp.get({ url: "http://192.168.169.1/setup/wifi/scan", responseType: 'json' });
            const networks = new Map<string, any>();

            for (const network of res.data) {
                if (!networks.has(network.ssid)) {
                    networks.set(network.ssid, network);
                }
            }

            return Array.from(networks.values());
        },
    });

    let joinNetworkQuery = useQuery({
        queryKey: ['setup/joinWifiNetwork'],
        networkMode: 'always',
        retry: false,
        refetchOnWindowFocus: false,
        enabled: isOpen && slideIndex === PAGES.JOIN,
        staleTime: 0,
        queryFn: async ({ signal }) => {
            console.log("Joining Wi-Fi network", ssid, password)
            const res = await CapacitorHttp.post({url: 'http://192.168.169.1/setup/wifi/join', data: JSON.stringify({ ssid, password }), headers: { 'Content-Type': 'application/json' }});

            if (res.status !== 200) {
                Haptics.notification({ type: NotificationType.Error });
                throw new Error("The device failed to connect to the Wi-Fi network.\n\nCheck the network details provided.");
            }

            disconnectFromDeviceWifi();
            Haptics.notification({ type: NotificationType.Success });

            return ssid;
        },
    });

    const claimDeviceQuery = useQuery({
        queryKey: ['setup/claimDevice'],
        retry: false,
        refetchOnWindowFocus: false,
        enabled: isOpen && slideIndex === PAGES.CLAIM,
        staleTime: 0,
        queryFn: async (): Promise<any> => {
            const deviceId = 'OC-' + qrCodeScanQuery.data?.toUpperCase();

            return OCGatewayClient.request('claimDevice', { deviceId });
        }
    });

    async function disconnectFromDeviceWifi() {
        const connectedDeviceWifiSsid = connectToDeviceQuery.data;

        if (connectedDeviceWifiSsid) {
            (window as any).wifiManager.disconnect(connectedDeviceWifiSsid, () => {
                console.log('Disconnected from', connectedDeviceWifiSsid);
            }, (e: any) => {
                console.error('Failed to disconnect from', connectedDeviceWifiSsid, e);
            });
        }
    }

    async function dismissModal() {
        setIsOpen(false);
        queryClient.cancelQueries({ queryKey: ['setup/scanDeviceCode'] })
        await modal.current?.dismiss();
        swiperInstance?.slideTo(0, 0, false);
        disconnectFromDeviceWifi();
    }

    function onPresentModal() {
        setIsOpen(true);
    }

    useEffect(() => {
        Network.getStatus().then(status => {
            setNetworkStatus(status);
        });

        const listener = Network.addListener('networkStatusChange', status => {
            setNetworkStatus(status);
        });

        return () => {
            listener.then(l => l.remove());
        };
    }, []);

    return (
        <IonModal ref={modal} trigger={trigger} onIonModalWillPresent={onPresentModal}>
            <IonHeader translucent className={styles.scanUX}>
                <IonToolbar>
                    <IonButtons slot="start">
                        {slideIndex === PAGES.CREDENTIALS && <IonButton onClick={() => swiperInstance!.slidePrev()}><IonIcon icon={arrowBack} /></IonButton>}
                    </IonButtons>
                    <IonTitle>
                        <div>Set Up a Device</div>
                        {slideIndex > 0 && <small>MAC Id: {qrCodeScanQuery.data}</small>}
                    </IonTitle>
                    <IonButtons slot="primary">
                        <IonButton onClick={dismissModal}>
                            <IonIcon slot="icon-only" icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <Swiper className={styles.swiper} allowTouchMove={false} onSwiper={(swiper: SwiperClass) => { swiperInstance = swiper; }} onSlideChange={(swiper: SwiperClass) => setSlideIndex(swiper.activeIndex)}>
                    <SwiperSlide className={styles.scanSlide}>
                        {qrCodeScanQuery.isFetching && <><IonIcon className={`${styles.scanUX} ${styles.viewfinder}`} src="/assets/device/scan.svg" />
                            <IonCard className={`${styles.scanUX} ${styles.scanInstructions}`}>
                                <IonCardContent>
                                    <IonCardTitle>Scan Setup Code</IonCardTitle>
                                    <IonItem lines="none" color="none">
                                        <IonIcon slot="start" icon={qrCode} />
                                        <IonLabel>Look for the QR code on the device screen. The device must be powered on and in setup mode.</IonLabel>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard></>}
                        {qrCodeScanQuery.isError && !qrCodeScanQuery.isFetching &&
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>Error Scanning Code</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {qrCodeScanQuery.error.message}
                                </IonCardContent>
                                <IonButton fill="clear" onClick={() => qrCodeScanQuery.refetch()}>Retry</IonButton>
                            </IonCard>
                        }
                    </SwiperSlide>
                    <SwiperSlide>
                        {connectToDeviceQuery.isFetching &&
                            <IonItem lines="none">
                                <IonSpinner slot="start" />
                                <IonLabel>
                                    <h2>Connecting...</h2>
                                </IonLabel>
                            </IonItem>
                        }
                        {connectToDeviceQuery.isError && !connectToDeviceQuery.isFetching &&
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>Couldn't Connect to Device</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <p>We couldn't connect to your device's Wi-Fi network.</p>
                                    <small>{connectToDeviceQuery.error.message}</small>
                                </IonCardContent>
                                <IonButton fill="clear" onClick={() => connectToDeviceQuery.refetch()}>Retry</IonButton>
                            </IonCard>
                        }
                    </SwiperSlide>
                    <SwiperSlide className={styles.scanWifiSlide}>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>WiFi Setup</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                Select your Wi-Fi network to connect your OnlyCat device to the internet.
                            </IonCardContent>
                        </IonCard>
                        <IonList inset={true}>
                            <IonItemDivider>
                                Wi-Fi Networks
                                {scanNetworksQuery.error && <IonIcon icon={alertCircleOutline} slot="end" />}
                                {scanNetworksQuery.isFetching && <IonSpinner name="dots" slot="end" />}
                            </IonItemDivider>
                            {scanNetworksQuery.data && scanNetworksQuery.data.map((network: any) => (
                                <IonItem key={network.ssid} onClick={() => { setSsid(network.ssid); setPassword(''); swiperInstance!.slideNext(); }}>
                                    {network.ssid}
                                    {!!network.auth && <IonIcon slot="end" icon={lockClosed} size="small" className={styles.authIcon} />}
                                    <IonIcon slot="end" src={rssiToSignalIcon(network.rssi)} />
                                </IonItem>
                            ))}
                            <IonItem onClick={() => { setSsid(''); setPassword(''); swiperInstance!.slideNext(); }}>
                                Other...
                            </IonItem>
                        </IonList>
                    </SwiperSlide>
                    <SwiperSlide className={styles.credentialsSlide}>
                        <IonList inset={true}>
                            <IonItem>
                                <IonInput type="text" label="Name" placeholder="Network Name (SSID)" autocomplete="off" value={ssid} onIonInput={e => setSsid(e.target.value as string)} />
                            </IonItem>
                            <IonItem>
                                <IonInput type="text" label="Password" autocomplete="off" value={password} onIonInput={e => setPassword(e.target.value as string)} />
                            </IonItem>
                        </IonList>
                        <IonButton onClick={() => { swiperInstance!.slideNext(); }}>Join</IonButton>
                    </SwiperSlide>
                    <SwiperSlide>
                        {joinNetworkQuery.isFetching &&
                            <IonItem lines="none">
                                <IonSpinner slot="start" />
                                <IonLabel>
                                    <h2>Joining Network...</h2>
                                </IonLabel>
                            </IonItem>
                        }
                        {joinNetworkQuery.isError && !joinNetworkQuery.isFetching &&
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>Couldn't Join Network</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {joinNetworkQuery.error.message}
                                </IonCardContent>
                                <IonButton fill="clear" onClick={() => swiperInstance!.slidePrev()}>Go Back</IonButton>
                            </IonCard>
                        }
                        {joinNetworkQuery.isSuccess && !joinNetworkQuery.isFetching &&
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>Device Connected</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    Your device has successfully connected to the Wi-Fi network.
                                </IonCardContent>
                                <IonButton fill="clear" onClick={() => { swiperInstance!.slideNext(); }}>Next</IonButton>
                            </IonCard>
                        }
                    </SwiperSlide>
                    <SwiperSlide>
                        {claimDeviceQuery.isFetching &&
                            <IonItem lines="none">
                                <IonSpinner slot="start" />
                                <IonLabel>
                                    <h2>Adding Device...</h2>
                                </IonLabel>
                            </IonItem>
                        }
                        {claimDeviceQuery.isError && !claimDeviceQuery.isFetching &&
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>Couldn't Add Device</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <p>Your device couldn't be added to your account.</p>
                                    <small>{claimDeviceQuery.error.message}</small>
                                </IonCardContent>
                                <IonButton fill="clear" onClick={() => claimDeviceQuery.refetch()}>Retry</IonButton>
                            </IonCard>
                        }
                        {claimDeviceQuery.isSuccess && !claimDeviceQuery.isFetching &&
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>Device Added</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    Your device has successfully been added to your account.
                                </IonCardContent>
                                <IonButton fill="clear" onClick={dismissModal}>Done</IonButton>
                            </IonCard>
                        }
                    </SwiperSlide>
                </Swiper>
            </IonContent>
        </IonModal>
    );
};

export default AddDeviceModal;
