import { IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonPage, IonProgressBar, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import React, { memo, useEffect, useState } from "react";
import OCGatewayClient from '../client/OCGatewayClient';
import OCPlatform from '../client/OCPlatform';
import OCPushNotifications from "../client/OCPushNotifications";
import OCAuth from '../client/OCAuth';
import SoftwareUpdateIndicatorButton from '../components/SoftwareUpdateIndicatorButton';
import useUX from '../utils/ux';
import { logOutOutline } from 'ionicons/icons';
import styles from './Account.module.css';

const Account: React.FC = () => {
  const [user, setUser] = useState<any>();
  const [deviceToken, setDeviceToken] = useState<string | undefined>();
  const [pushEnvironment, setPushEnvironment] = useState<string | undefined>();
  const [presentAlert] = useIonAlert();
  const { isConnecting } = useUX();

  const login = async () => {
    await OCAuth.login();
  };

  const logout = async () => {
    await OCAuth.logout();
  }

  useEffect(() => {
    const subscriptions = [
      OCGatewayClient.user$.subscribe(value => setUser(value))
    ];

    OCPlatform.getDeviceToken().then(setDeviceToken);
    OCPushNotifications.getPushEnvironment().then(setPushEnvironment);

    return () => {
      subscriptions.forEach(s => s.unsubscribe());
    };
  }, []);

  useEffect(() => {
    async function handleAuthError(event: any) {
      const err = event.detail;

      await presentAlert({
        header: 'Login Error',
        subHeader: err.message,
        message: 'Something went wrong while logging you in. Please try again, or contact support.',
        buttons: ['Dismiss'],
      });
    }

    document.addEventListener('oc:auth:error', handleAuthError);

    OCAuth.handleRedirects();

    return () => {
      document.removeEventListener('oc:auth:error', handleAuthError);
    }
  }, [presentAlert]);

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Account</IonTitle>
          <IonButtons slot="primary">
            <SoftwareUpdateIndicatorButton />
          </IonButtons>
          {!!(isConnecting || typeof user === 'undefined') && <IonProgressBar type="indeterminate" />}
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.accountContent} fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Account</IonTitle>
          </IonToolbar>
        </IonHeader>

        {<div className={styles.avatarContainer}>
          <IonAvatar>
            <img src={user?.avatarUrl ?? '/assets/account/default-avatar.svg'} alt={user?.name ?? 'Guest'} referrerPolicy="no-referrer" />
          </IonAvatar>
          <h1>{user?.name ?? "Not Signed In"}</h1>
          <h2>{user?.email}</h2>
        </div>}

        {!user && <IonButton onClick={login} fill="outline" shape="round" color="primary" expand="full">
          <IonIcon slot="start" icon={logOutOutline}></IonIcon>
          Sign In
        </IonButton>}

        <IonList inset>
          <IonItemDivider>User Information</IonItemDivider>
          <IonItem>
            <IonLabel>Device Token</IonLabel>
            {deviceToken}
          </IonItem>
          <IonItem>
            <IonLabel>User Id</IonLabel>
            {user?.id ?? '-'}
          </IonItem>
          {user && <IonItem routerLink="/account/delete">
            <IonLabel color="danger">Delete Account</IonLabel>
          </IonItem>}
        </IonList>

        <IonList inset>
          <IonItemDivider>Push Notifications</IonItemDivider>
          <IonItem>
            <IonLabel>Push Environment</IonLabel>
            {pushEnvironment}
          </IonItem>
        </IonList>

        {user && <IonButton onClick={logout} fill="clear" shape="round" color="danger" expand="full">
          <IonIcon slot="start" icon={logOutOutline}></IonIcon>
          Sign Out
        </IonButton>}
      </IonContent>
    </IonPage >
  );
};

export default memo(Account);
