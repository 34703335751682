import { IonButton, IonContent, IonHeader, IonInput, IonItem, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonButtons } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useRfidProfileMutation, useRfidProfileQuery } from '../query/rfidProfileQuery';

const RfidProfileModal = ({ dismiss, rfidCode }: { dismiss: (data?: string | null | undefined | number, role?: string) => void, rfidCode: string }) => {
    const query = useRfidProfileQuery(rfidCode);
    const mutation = useRfidProfileMutation();
    const inputRef = useRef<HTMLIonInputElement>(null);
    const [label, setLabel] = useState(query.data?.label);

    useEffect(() => {
        setLabel(query.data?.label);
    }, [query.data?.label]);

    const save = async () => {
        await mutation.mutateAsync({ rfidCode, label });

        dismiss(inputRef.current?.value, 'confirm');
    }

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={() => dismiss(null, 'cancel')}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Pet Profile</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={save} strong={true} disabled={query.data?.label === label || mutation.isPending}>
                            Save
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonItem>
                    <IonInput labelPlacement="stacked" label="Microchip Code" value={rfidCode} readonly />
                </IonItem>
                <IonItem>
                    <IonInput ref={inputRef} labelPlacement="stacked" label="Name" placeholder="Name" value={label} maxlength={32} onIonInput={(e) => setLabel(e.detail.value)} />
                </IonItem>
            </IonContent>
        </IonPage>
    );
};

export default RfidProfileModal;